export default [
  {
    key: 'name',
    label: 'field.positionName',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'isEnable',
    label: 'status.active',
    rules: '',
    type: 'checkbox',
    cols: '12',
  },
]
